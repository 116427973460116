<template>
  <v-container fluid class="pt-0 px-0">
    <v-row>
      <v-col cols="1" md="1" class="px-0 pt-2 pb-2">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              size="24"
              v-on="on"
              @click="goBack()"
              tabindex="-1"
            >
              {{ volverIcon }}
            </v-icon>
          </template>
          <span>Atrás</span>
        </v-tooltip>
      </v-col>
      <v-col cols="11" md="8" class="pb-0 pt-0 px-0" tabindex="-1">
        <PageHeader :title="title" class="pt-0 pb-0" />
      </v-col>
      <v-col cols="12" class="p-0">
        <v-card outlined>
          <v-card-text class="py-1 px-5">
            <v-form
              v-model="isFormValid"
              form="form-pagos-masivos"
              ref="form-pagos-masivos"
              id="form-pagos-masivos"
              @submit.prevent="generarOrdenesPago()"
            >
              <v-row dense>
                <v-col class="p-0 pb-1" cols="12" md="4">
                  <v-autocomplete
                    v-model="empresaSelected"
                    return-object
                    :items="empresas"
                    outlined
                    @change="getTiposComprobantes(), getTiposValores()"
                    item-value="id"
                    :rules="rules.required"
                    :disabled="showSaveOpFields"
                    tabindex="1"
                    :filled="showSaveOpFields"
                    item-text="value"
                    clearable
                    ref="btnComboEmpresas"
                    id="btnComboEmpresas"
                    hide-details="auto"
                    dense
                    label="Empresa"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="2" class="p-0 pb-1 pl-1">
                  <v-autocomplete
                    v-model="monedaProvSelected"
                    :items="monedas"
                    return-object
                    v-if="parametroGeneralMultimoneda"
                    item-value="id"
                    item-text="value"
                    outlined
                    tabindex="14"
                    :rules="rules.required"
                    :disabled="comprobantes.length > 0 || showSaveOpFields"
                    :filled="comprobantes.length > 0 || showSaveOpFields"
                    clearable
                    dense
                    label="Moneda prov."
                    hide-details="auto"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="p-0 pb-1 pl-1">
                  <v-autocomplete
                    v-model="monedaOPSelected"
                    :items="monedas"
                    return-object
                    v-if="parametroGeneralMultimoneda"
                    item-value="id"
                    item-text="value"
                    outlined
                    :disabled="showSaveOpFields"
                    :filled="showSaveOpFields"
                    tabindex="15"
                    :rules="rules.required"
                    clearable
                    dense
                    label="Moneda OP"
                    hide-details="auto"
                    @change="getTiposValores()"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="2" class="p-0 pb-1 pl-1 pr-4">
                  <currency-input
                    label="Cotización"
                    v-model="cotizacion"
                    v-if="showCotizacion && parametroGeneralMultimoneda"
                    dense
                    class="right-input"
                    :disabled="comprobantes.length > 0 || showSaveOpFields"
                    :filled="comprobantes.length > 0 || showSaveOpFields"
                    outlined
                    :rules="[]"
                    :options="currencyOptions"
                    :hideDetails="'true'"
                  ></currency-input>
                </v-col>
                <v-col cols="12" md="6" class="pl-4 pr-3">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="p-0 pt-1"
                      v-if="showSaveOpFields"
                    >
                      <v-autocomplete
                        v-model="tipoComprobanteSelected"
                        :items="tiposComprobantes"
                        ref="tipoComprobante"
                        outlined
                        return-object
                        clearable
                        :loading="tiposComprobantesLoading"
                        :rules="rules.required"
                        :disabled="opGenerated"
                        :filled="opGenerated"
                        item-value="id"
                        item-text="value"
                        dense
                        label="Tipo comprobante"
                        hide-details="auto"
                        @change="setDatosTipoComprobante()"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="3" class="p-0 px-4">
                      <v-row>
                        <v-col
                          class="p-0 pl-1 pt-1"
                          md="5"
                          v-if="showSaveOpFields"
                        >
                          <v-text-field
                            v-model="letraComprobante"
                            :rules="rules.required"
                            v-mask="'A'"
                            dense
                            outlined
                            hide-details="auto"
                            label="Letra"
                            @change="
                              getTipoCompNumAutomatico(
                                tipoComprobanteSelected,
                                letraComprobante,
                                sucursalComprobante
                              )
                            "
                            :filled="tieneNumeradorAutomatico || opGenerated"
                            :disabled="tieneNumeradorAutomatico || opGenerated"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          class="p-0 pl-1 pt-1"
                          md="7"
                          v-if="showSaveOpFields"
                        >
                          <v-text-field
                            v-model="sucursalComprobante"
                            :rules="[
                              rules.decimalGreaterThanZero(
                                parseInt(sucursalComprobante)
                              )
                            ]"
                            @change="
                              getTipoCompNumAutomatico(
                                tipoComprobanteSelected,
                                letraComprobante,
                                sucursalComprobante
                              )
                            "
                            v-mask="'#####'"
                            dense
                            outlined
                            hide-details="auto"
                            label="Sucursal"
                            :filled="tieneNumeradorAutomatico || opGenerated"
                            :disabled="tieneNumeradorAutomatico || opGenerated"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      class="p-0 pl-1 pr-1 pt-1"
                      cols="4"
                      md="3"
                      v-if="showSaveOpFields"
                    >
                      <v-text-field
                        v-model="numeroComprobante"
                        v-mask="'#########'"
                        dense
                        outlined
                        hide-details="auto"
                        label="Número"
                        :rules="
                          tieneNumeradorAutomatico
                            ? []
                            : [rules.decimalGreaterThanZero(numeroComprobante)]
                        "
                        :filled="tieneNumeradorAutomatico || opGenerated"
                        :disabled="tieneNumeradorAutomatico || opGenerated"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="px-4" cols="6" md="3">
                  <v-row dense>
                    <v-col cols="6" class="pr-0 pb-0" v-if="showSaveOpFields">
                      <v-menu
                        ref="fecha"
                        v-model="menuFecha"
                        :close-on-content-click="false"
                        :filled="opGenerated"
                        :disabled="opGenerated"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaSelected"
                            label="Fecha"
                            hint="Formato DD/MM/AAAA"
                            @blur="
                              fecha = helpers.parseDateToIso(fechaSelected)
                            "
                            outlined
                            :filled="opGenerated"
                            :disabled="opGenerated"
                            autocomplete="not"
                            hide-details="auto"
                            dense
                            clearable
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.required.concat(rules.validDate)"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fecha"
                          no-title
                          @change="fechaSelected = helpers.formatDate(fecha)"
                          @input="menuFecha = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="6" class="pr-0 pb-0" v-if="showSaveOpFields">
                      <v-menu
                        :disabled="disableFecContable || opGenerated"
                        :filled="disableFecContable || opGenerated"
                        ref="fechaContable"
                        v-model="menuFechaContable"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaContableSelected"
                            label="Fecha cble."
                            hint="Formato DD/MM/AAAA"
                            @blur="
                              fechaContable = helpers.parseDateToIso(
                                fechaContableSelected
                              )
                            "
                            outlined
                            :disabled="disableFecContable || opGenerated"
                            :filled="disableFecContable || opGenerated"
                            autocomplete="not"
                            hide-details="auto"
                            dense
                            clearable
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            v-on="on"
                            :rules="rules.required.concat(rules.validDate)"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaContable"
                          no-title
                          @change="
                            fechaContableSelected = helpers.formatDate(
                              fechaContable
                            )
                          "
                          @input="menuFechaContable = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- FECHA CONTABLE -->
                <v-col
                  cols="12"
                  md="3"
                  class="pt-2 pr-0"
                  v-if="showSaveOpFields"
                >
                  <v-text-field
                    v-model="conceptoPago"
                    dense
                    outlined
                    :filled="opGenerated"
                    :disabled="opGenerated"
                    hide-details="auto"
                    label="Concepto del pago"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pl-4 pr-3">
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                      class="p-0 pt-1"
                      v-if="showSaveOpFields"
                    >
                      <v-autocomplete
                        v-model="tipoValorSelected"
                        :items="tiposValores"
                        ref="tipoValor"
                        outlined
                        return-object
                        @change="changeTipoValor()"
                        clearable
                        :loading="tiposValoresLoading"
                        :rules="rules.required"
                        item-text="tvNombre"
                        item-value="tvId"
                        dense
                        :filled="opGenerated"
                        :disabled="opGenerated"
                        label="Tipo de valor"
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col class="p-0 pl-1 pt-1" md="3" v-if="showSaveOpFields">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Banco"
                        v-model="bancoNombre"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col class="p-0 pl-1 pt-1" md="2" v-if="showSaveOpFields">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Cuenta"
                        v-model="cuentaNombre"
                        autocomplete="off"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" class="p-0 pl-1 pt-1" v-if="showSaveOpFields">
                      <v-text-field
                        v-model.trim="numero"
                        type="text"
                        label="Número"
                        dense
                        outlined
                        :disabled="!this.disableNumero || opGenerated"
                        :filled="!this.disableNumero || opGenerated"
                        :rules="
                          this.disableNumero
                            ? rules.required.concat(
                                rules.positiveNumberHigher0,
                                [rules.maxLength(numero, 10)]
                              )
                            : []
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="2"
                      class="p-0 pl-1 pr-1 pt-1"
                      v-if="showSaveOpFields"
                    >
                      <v-menu
                        ref="fechaContable"
                        v-model="menuFechaValor"
                        :close-on-content-click="false"
                        :disabled="opGenerated"
                        :filled="opGenerated"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="fechaValorSelected"
                            label="Fecha"
                            hint="Formato DD/MM/AAAA"
                            @blur="
                              fechaValor = helpers.parseDateToIso(
                                fechaValorSelected
                              )
                            "
                            outlined
                            :filled="!disableFechaValor || opGenerated"
                            autocomplete="not"
                            hide-details="auto"
                            dense
                            clearable
                            v-mask="'##/##/####'"
                            v-bind="attrs"
                            v-on="on"
                            :rules="disableFechaValor ? rules.required : []"
                            :disabled="!disableFechaValor || opGenerated"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="fechaValor"
                          no-title
                          @change="
                            fechaValorSelected = helpers.formatDate(fechaValor)
                          "
                          @input="menuFechaValor = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="8" class="py-0 px-0">
                      <p
                        class="ml-5"
                        style="color: red; font-size: 14px"
                        v-if="
                          atLeastOneProvWithError &&
                            !isChangeStepLoading &&
                            showSaveOpFields
                        "
                      >
                        No se generará la orden de pago correspondiente para
                        él/los proveedor/es marcados en rojo.
                      </p></v-col
                    >
                    <v-col cols="4" class="py-0 px-0" v-if="!opGenerated">
                      <v-btn
                        small
                        color="primary"
                        type="submit"
                        form="form-pagos-masivos"
                        class="to-right no-upper-case"
                        v-if="showSaveOpFields"
                        :disabled="!isFormValid"
                        :loading="loadingSaveBtn"
                        >Generar órdenes de pago</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <!-- Comprobantes a cancelar -->
            <v-row dense>
              <v-col cols="12" class="pb-0 px-0">
                <v-card outlined class="pb-3">
                  <v-row>
                    <v-col cols="12" md="6" class="pt-2 pb-0 pl-6">
                      <span
                        class="primary--text font-weight-medium py-0 pl-2"
                        >{{
                          showSaveOpFields
                            ? "Proveedores para generar pagos"
                            : "Comprobantes a cancelar"
                        }}</span
                      >
                    </v-col>
                  </v-row>
                  <v-card-text class="py-1">
                    <v-data-table
                      :headers="computedHeadersComprobantes"
                      :items="
                        showSaveOpFields ? comprobantesGrouped : comprobantes
                      "
                      :items-per-page="5"
                      :search="search"
                      dense
                      class="elevation-1 custom-data-table"
                    >
                      <template v-slot:top>
                        <v-row dense>
                          <v-col cols="12" md="6" class="py-0 pl-6">
                            <v-text-field
                              v-model="search"
                              :append-icon="searchIcon"
                              label="Buscar"
                              dense
                              outlied
                              single-line
                              hide-details
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" class="px-2 pt-1 text-right">
                            <v-btn
                              small
                              class="px-4 mx-2"
                              outlined
                              color="primary"
                              :disabled="comprobantes.length == 0"
                              v-if="!opGenerated"
                              @click="quitarComprobantes()"
                              >Quitar todos</v-btn
                            >

                            <v-btn
                              small
                              class="px-4 mx-2"
                              outlined
                              color="primary"
                              :disabled="
                                comprobantes.length == 0 || showSaveOpFields
                              "
                              v-if="!showSaveOpFields"
                              @click="exportComprobantes()"
                              >Exportar</v-btn
                            >

                            <v-btn
                              small
                              class="pl-4 ml-2 mr-5"
                              color="primary"
                              @click="buscarComprobantesCancelar = true"
                              :disabled="
                                parametroGeneralMultimoneda
                                  ? empresaSelected == null ||
                                    monedaProvSelected == null
                                  : empresaSelected == null
                              "
                              v-if="!showSaveOpFields"
                              >Agregar comprobantes</v-btn
                            >
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:[`header.nroComp`]>
                        <strong style="font-size: 11px">N° comp.</strong>
                      </template>
                      <template v-slot:[`header.fechaComprobanteFormatted`]>
                        <strong style="font-size: 11px">Fecha comp.</strong>
                      </template>
                      <template v-slot:[`header.fechaVencimientoFormatted`]>
                        <strong style="font-size: 11px">Vencimiento</strong>
                      </template>
                      <template v-slot:[`header.total`]>
                        <strong style="font-size: 11px" class="text-right"
                          >Total</strong
                        >
                      </template>
                      <template v-slot:[`header.cancelado`]>
                        <strong style="font-size: 11px">Cancelado</strong>
                      </template>
                      <template v-slot:[`header.saldo`]>
                        <strong style="font-size: 11px">Saldo</strong>
                      </template>
                      <template v-slot:[`header.saldoComp`]>
                        <strong style="font-size: 11px">Saldo</strong>
                      </template>
                      <template v-slot:[`header.cancela`]>
                        <strong
                          style="font-size: 11px; width: 100px; display: inline-block;"
                          >Cancela</strong
                        >
                      </template>
                      <template v-slot:[`header.cancelaComp`]>
                        <strong
                          style="font-size: 11px; width: 100px; display: inline-block;"
                          >Cancela</strong
                        >
                      </template>
                      <template v-slot:[`header.cotizacionComprobante`]>
                        <strong style="font-size: 11px">Cotiz. comp.</strong>
                      </template>
                      <template v-slot:[`header.diferenciaCambio`]>
                        <strong style="font-size: 11px">Dif. de cambio</strong>
                      </template>
                      <template v-slot:[`header.actions`]>
                        <strong style="font-size: 11px">Acc.</strong>
                      </template>
                      <template
                        v-slot:[`header.provNom`]
                        v-if="showSaveOpFields"
                      >
                        <strong style="font-size: 11px">Proveedor</strong>
                      </template>
                      <template
                        v-slot:[`header.totalNetoProporcional`]
                        v-if="showSaveOpFields"
                      >
                        <strong style="font-size: 11px">Total neto</strong>
                      </template>
                      <template
                        v-slot:[`header.importeMonedaProv`]
                        v-if="showSaveOpFields"
                      >
                        <strong style="font-size: 11px">{{
                          "Importe " + monedaProvSelected.value.toLowerCase()
                        }}</strong>
                      </template>
                      <template
                        v-slot:[`header.importeMonedaOP`]
                        v-if="showSaveOpFields"
                      >
                        <strong style="font-size: 11px">{{
                          "Importe " + monedaOPSelected.value.toLowerCase()
                        }}</strong>
                      </template>
                      <template
                        v-slot:[`header.totalDifCambio`]
                        v-if="showSaveOpFields"
                      >
                        <strong style="font-size: 11px">Dif. cambio</strong>
                      </template>
                      <template v-slot:[`header.nroOp`] v-if="showSaveOpFields">
                        <strong style="font-size: 11px">N° OP</strong>
                      </template>
                      <template
                        v-slot:[`item.provNom`]="{ item }"
                        v-if="showSaveOpFields"
                      >
                        <span
                          style="font-size: 12px;"
                          :style="
                            !item.confGan || !item.confIB ? 'color: red;' : ''
                          "
                          >{{ item.provNom }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.totalNetoProporcional`]="{ item }"
                        v-if="showSaveOpFields"
                      >
                        <span
                          style="font-size: 12px"
                          :style="
                            !item.confGan || !item.confIB ? 'color: red;' : ''
                          "
                          >{{
                            helpers.floatToMoneyString(
                              item.totalNetoProporcional,
                              "",
                              true
                            )
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.importeMonedaProv`]="{ item }"
                        v-if="showSaveOpFields"
                      >
                        <span
                          style="font-size: 12px"
                          :style="
                            !item.confGan || !item.confIB ? 'color: red;' : ''
                          "
                          >{{
                            helpers.floatToMoneyString(
                              item.importeMonedaProv,
                              "",
                              true
                            )
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.importeMonedaOP`]="{ item }"
                        v-if="showSaveOpFields"
                      >
                        <span
                          style="font-size: 12px"
                          :style="
                            !item.confGan || !item.confIB ? 'color: red;' : ''
                          "
                          >{{
                            helpers.floatToMoneyString(
                              item.importeMonedaOP,
                              "",
                              true
                            )
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.totalDifCambio`]="{ item }"
                        v-if="showSaveOpFields"
                      >
                        <span
                          style="font-size: 12px"
                          :style="
                            !item.confGan || !item.confIB ? 'color: red;' : ''
                          "
                          >{{
                            helpers.floatToMoneyString(
                              item.totalDifCambio,
                              "",
                              true
                            )
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.nroOp`]="{ item }"
                        v-if="showSaveOpFields"
                      >
                        <span
                          style="font-size: 12px"
                          :style="
                            !item.confGan || !item.confIB ? 'color: red;' : ''
                          "
                          >{{ item.nroOp }}
                        </span>
                      </template>

                      <template v-slot:[`item.nroComp`]="{ item }">
                        <span style="font-size: 12px">{{ item.nroComp }} </span>
                      </template>
                      <template
                        v-slot:[`item.fechaComprobanteFormatted`]="{ item }"
                      >
                        <span style="font-size: 12px"
                          >{{ item.fechaComprobanteFormatted }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.fechaVencimientoFormatted`]="{ item }"
                      >
                        <span style="font-size: 12px"
                          >{{ item.fechaVencimientoFormatted }}
                        </span>
                      </template>
                      <template v-slot:[`item.total`]="{ item }">
                        <span style="font-size: 12px"
                          >{{
                            helpers.floatToMoneyString(item.total, "", true)
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.cancelado`]="{ item }">
                        <span style="font-size: 12px"
                          >{{
                            helpers.floatToMoneyString(item.cancelado, "", true)
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.saldo`]="{ item }">
                        <span style="font-size: 12px"
                          >{{
                            helpers.floatToMoneyString(item.saldo, "", true)
                          }}
                        </span>
                      </template>
                      <template v-slot:[`item.saldoComp`]="{ item }">
                        <span style="font-size: 12px"
                          >{{
                            helpers.floatToMoneyString(item.saldoComp, "", true)
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.cotizacionComprobante`]="{ item }"
                      >
                        <span style="font-size: 12px"
                          >{{
                            helpers.floatToMoneyString(
                              item.cotizacionComprobante,
                              "",
                              true
                            )
                          }}
                        </span>
                      </template>
                      <template
                        v-slot:[`item.cancela`]="{ item }"
                        v-if="
                          monedaProvSelected != null &&
                            monedaProvSelected.id == monedaPorDefecto
                        "
                      >
                        <currency-input
                          v-model="item.cancela"
                          dense
                          hide-details="auto"
                          style="max-width: 140px; font-size: 12px; width: 140px; display: inline-block;"
                          class="to-right right-input"
                          outlined
                          :rules="[
                            rules.decimalEqualOrLess(item.cancela, item.saldo),
                            rules.decimalGreaterThanZero(item.cancela)
                          ]"
                          @input="
                            () => {
                              if (showCotizacion)
                                (item.cancelaComp = item.cancela / cotizacion),
                                  calcularDiferenciaCambio(cotizacion);
                              else item.cancelaComp = 0;
                            }
                          "
                          :options="currencyOptions"
                        ></currency-input>
                      </template>
                      <template
                        v-slot:[`item.cancelaComp`]="{ item }"
                        v-if="
                          monedaProvSelected != null &&
                            monedaProvSelected.id != monedaPorDefecto
                        "
                      >
                        <currency-input
                          v-model="item.cancelaComp"
                          dense
                          hide-details="auto"
                          style="max-width: 140px; font-size: 12px; width: 140px; display: inline-block;"
                          class="to-right right-input"
                          outlined
                          :rules="[
                            rules.decimalEqualOrLess(
                              item.cancelaComp,
                              item.saldoComp
                            ),
                            rules.decimalGreaterThanZero(item.cancelaComp)
                          ]"
                          @input="
                            (item.cancela =
                              item.cancelaComp * item.cotizacionComprobante),
                              calcularDiferenciaCambio(cotizacion)
                          "
                          :options="currencyOptions"
                        ></currency-input>
                      </template>

                      <template v-slot:[`item.diferenciaCambio`]="{ item }">
                        <v-tooltip top max-width="20rem">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-on="on"
                              v-bind="attrs"
                              style="font-size: 12px"
                              >{{
                                helpers.floatToMoneyString(
                                  item.diferenciaCambio,
                                  null,
                                  true
                                )
                              }}
                              <v-icon class="mt-0" small>
                                {{ infoIcon }}
                              </v-icon></span
                            >
                          </template>
                          <v-row>
                            <v-col cols="12" class="p-0">
                              <span>
                                (Cancela * Cotiz. O.P) - (Cancela * Cotiz.
                                Fact.)
                              </span>
                            </v-col>
                            <v-col cols="12" class="p-0">
                              <span>
                                {{
                                  `${helpers.floatToMoneyString(
                                    monedaProvSelected == monedaPorDefecto
                                      ? item.cancela
                                      : item.cancelaComp,
                                    "",
                                    true
                                  )} * ${helpers.floatToMoneyString(
                                    parseFloat(cotizacion),
                                    "",
                                    true
                                  )} - ${helpers.floatToMoneyString(
                                    monedaProvSelected == monedaPorDefecto
                                      ? item.cancela
                                      : item.cancelaComp,
                                    "",
                                    true
                                  )} * ${
                                    item.cotizacionComprobanteFormatted
                                  } = ${helpers.floatToMoneyString(
                                    item.diferenciaCambio,
                                    "",
                                    true
                                  )}`
                                }}
                              </span>
                            </v-col>
                          </v-row>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip
                          top
                          v-if="
                            showSaveOpFields && (!item.confGan || !item.confIB)
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              small
                              class="pl-2"
                              color="red"
                              v-on="on"
                            >
                              {{ infoIcon }}
                            </v-icon>
                          </template>
                          <span>{{
                            !item.confGan && !item.confIB
                              ? "El proveedor se encuentra sin condición y/o conceptos de Ganancias e Ingresos Brutos"
                              : !item.confGan && item.confIB
                              ? "El proveedor se encuentra sin condición y/o conceptos de Ganancias"
                              : item.confGan && !item.confIB
                              ? "El proveedor se encuentra sin condición y/o conceptos de Ingresos Brutos"
                              : ""
                          }}</span>
                        </v-tooltip>
                        <v-tooltip top v-if="showSaveOpFields">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" small class="pl-2" v-on="on">
                              {{ arrowDownIcon }}
                            </v-icon>
                          </template>
                          <span
                            v-html="getComprobantesList(item.provId)"
                          ></span>
                        </v-tooltip>

                        <v-tooltip
                          top
                          v-if="!showSaveOpFields || item.nroOp != null"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              small
                              class="pl-2"
                              v-on="on"
                              @click="goToVerComprobante(item)"
                            >
                              {{ seeIcon }}
                            </v-icon>
                          </template>
                          <span>Ver comprobante</span>
                        </v-tooltip>
                        <v-tooltip top v-if="!opGenerated">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              small
                              v-on="on"
                              @click="deleteItem(item)"
                            >
                              {{ deleteIcon }}
                            </v-icon>
                          </template>
                          <span>Eliminar</span>
                        </v-tooltip>
                      </template>
                      <template v-slot:bottom>
                        <p>Hola</p>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense class="pt-3 px-5">
              <v-spacer></v-spacer>
              <v-col
                cols="12"
                md="2"
                class="pl-0 text-left"
                v-if="!showSaveOpFields"
              >
                <currency-input
                  v-model.trim="porcentajeCancelar"
                  label="Porcentaje a cancelar"
                  :options="currencyOptionsForPorcentaje"
                  persistent-hint
                  :append-icon="porcentajeIcon"
                  :rules="[]"
                  class="right-input"
                >
                </currency-input>
              </v-col>

              <v-col
                cols="12"
                md="2"
                class="px-0 text-left"
                v-if="!showSaveOpFields"
              >
                <v-btn
                  small
                  class="px-4 mx-2 mt-1"
                  color="primary"
                  @click="aplicarPorcentaje()"
                  :disabled="
                    comprobantes.length == 0 ||
                      porcentajeCancelar > 100 ||
                      porcentajeCancelar == null
                  "
                >
                  Aplicar
                </v-btn>
              </v-col>

              <v-col cols="12" md="2" class="px-0 text-right">
                <currency-input
                  :label="`Total (${monedaPorDefectoNom})`"
                  v-model="totalEnMonedaPorDefecto"
                  disabled
                  filled
                  dense
                  :rules="[]"
                  :options="currencyOptions"
                  class="right-input"
                  @change="calcularTotalEnDolar()"
                  outlined
                  :hideDetails="'auto'"
                ></currency-input>
                <currency-input
                  v-if="showCotizacion"
                  :label="`Total (${monedaExtranjeraNom})`"
                  v-model="totalEnMonedaExtranjera"
                  @change="calcularTotalEnPeso()"
                  disabled
                  filled
                  :hideDetails="'auto'"
                  class="right-input pt-3"
                  dense
                  :rules="[]"
                  :options="currencyOptions"
                  outlined
                ></currency-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="pb-0 px-0">
        <v-btn
          class="to-right"
          color="primary"
          small
          :disabled="
            comprobantes.length == 0 ||
              empresaSelected == null ||
              monedaProvSelected == null ||
              monedaOPSelected == null
          "
          :loading="isChangeStepLoading"
          @click="changeStep()"
          v-if="!showSaveOpFields || opGenerated"
          >{{ opGenerated ? "Finalizar" : "Siguiente" }}</v-btn
        >
        <v-btn
          color="primary"
          @click="
            showSaveOpFields = false;
            calcularTotales();
          "
          small
          v-if="showSaveOpFields && !opGenerated"
          >Anterior</v-btn
        >
      </v-col>
    </v-row>
    <!-- MODAL BUSCAR COMPROBANTES A CANCELAR -->
    <v-dialog
      v-model="buscarComprobantesCancelar"
      v-if="buscarComprobantesCancelar"
      @keydown.esc="toggleModalComprobantesCancelar"
      max-width="100%"
      persistent
    >
      <BusquedaComprobantesCancelar
        :empresa="empresaSelected"
        :monedaProv="monedaProvSelected"
        :cotizacionActual="parseFloat(cotizacion)"
        :monedaOP="monedaOPSelected"
        :monedaPorDefecto="monedaPorDefecto"
        :pagosMasivosFilter="true"
        :showDiferenciaCambio="
          monedaPorDefecto != monedaProvSelected.id &&
            parametroGeneralMultimoneda
        "
        @toggleModalComprobantesCancelar="toggleModalComprobantesCancelar"
        @setComprobantesCancelar="setComprobantesCancelar"
      ></BusquedaComprobantesCancelar>
    </v-dialog>
    <v-dialog
      v-if="openSeeComp"
      v-model="openSeeComp"
      max-width="80%"
      @keydown.esc="openSeeComp = false"
      persistent
    >
      <DetalleComprobanteProvDialog
        @closeModalDelete="openSeeComp = false"
        :paramsToSeeCompDialog="paramsToSeeComp"
        :isDelete="false"
      ></DetalleComprobanteProvDialog>
    </v-dialog>
    <ConfirmDialog
      :text="textConfirm"
      :openConfirm="openConfirmDialog"
      :submitBtn="'SÍ'"
      :cancelBtn="'NO'"
      @update:openConfirm="openConfirmDialog = false"
      @onConfirm="
        confirmSaveValor = true;
        generarOrdenesPago();
      "
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import PageHeader from "@/components/ui/PageHeader";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import BusquedaComprobantesCancelar from "@/components/modules/proveedores/ordenesPago/BusquedaComprobantesCancelar.vue";
import helpers from "@/utils/helpers.js";
import DetalleComprobanteProvDialog from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProvDialog.vue";
import { mask } from "vue-the-mask";
import helpersExcel from "@/utils/helpers/importExportExcel";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "PagosMasivosOrdenesPago",
  directives: { mask },
  components: {
    PageHeader,
    CurrencyInput,
    BusquedaComprobantesCancelar,
    DetalleComprobanteProvDialog,
    ConfirmDialog
  },
  data() {
    return {
      volverIcon: enums.icons.BACK,
      arrowDownIcon: enums.icons.MORE_ITEMS,
      title: "Pagos masivos",
      routeToGo: "OrdenesDePago",
      rules: rules,
      helpers: helpers,
      cotizacion: 1,
      isFormValid: false,
      openSeeComp: false,
      empresaSelected: null,
      empresas: [],
      monedaProvSelected: null,
      monedas: [],
      parametroGeneralMultimoneda: false,
      monedaOPSelected: null,
      monedaPorDefecto: null,
      monedaPorDefectoNom: null,
      siglaMonedaPorDefecto: null,
      currencyOptions: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        valueRange: {
          min: -999999999999999,
          max: 999999999999999
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      comprobantes: [],
      search: "",
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      infoIcon: enums.icons.SNB_INFO,
      deleteIcon: enums.icons.DELETE,
      totalEnMonedaExtranjera: 0.0,
      totalEnMonedaPorDefecto: 0.0,
      porcentajeCancelar: 100,
      porcentajeIcon: enums.icons.PERCENT_OUTLINE,
      currencyOptionsForPorcentaje: {
        locale: "nl-NL",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: 0,
          max: 100
        },
        precision: undefined,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: false,
        autoDecimalDigits: false,
        exportValueAsInteger: false,
        autoSign: true,
        useGrouping: true
      },
      buscarComprobantesCancelar: false,
      paramsToSeeComp: null,
      showSaveOpFields: false,
      tipoComprobanteSelected: null,
      tiposComprobantes: [],
      tiposComprobantesLoading: false,
      tipoValorSelected: null,
      tiposValores: [],
      tiposValoresLoading: false,
      letraComprobante: null,
      sucursalComprobante: null,
      numeroComprobante: null,
      conceptoPago: null,
      fecha: null,
      menuFecha: null,
      fechaSelected: null,
      fechaContable: null,
      menuFechaContable: null,
      fechaContableSelected: null,
      tCompSigno: 1,
      tieneNumeradorAutomatico: false,
      disableFecContable: false,
      bancoNombre: null,
      cuentaNombre: null,
      numero: null,
      menuFechaValor: false,
      fechaValorSelected: null,
      fechaValor: null,
      fechaSiguiente: null,
      disableFechaValor: false,
      disableNumero: false,
      comprobantesGrouped: [],
      loadingSaveBtn: false,
      opGenerated: false,
      isChangeStepLoading: false,
      atLeastOneProvWithError: false,
      textConfirm:
        "El número de cheque anterior no está utilizado. ¿Desea continuar?",
      openConfirmDialog: false,
      confirmSaveValor: false
    };
  },
  watch: {
    monedaProvSelected(val) {
      this.actualizarCotizacion();
    },
    monedaOPSelected(val) {
      this.actualizarCotizacion();
    },
    comprobantes: {
      handler(newVal) {
        // Recalcular los totales cuando los comprobantes cambian
        if (newVal.length > 0) this.calcularTotales();
        else {
          this.totalEnMonedaExtranjera = 0.0;
          this.totalEnMonedaPorDefecto = 0.0;
        }
      },
      deep: true
    },
    comprobantesGrouped: {
      handler(newValue) {
        this.totalEnMonedaPorDefecto = newValue.reduce(
          (counter, comp) =>
            comp.confGan && comp.confIB
              ? counter + comp.sumaCancela + comp.totalDifCambio
              : counter,
          0
        );

        this.totalEnMonedaExtranjera = newValue.reduce(
          (counter, comp) =>
            comp.confGan && comp.confIB
              ? counter + comp.sumaCancelaComp
              : counter,
          0
        );
      },
      deep: true
    }
  },
  computed: {
    showCotizacion() {
      let monedaOpDistinta = true;
      if (this.monedaOPSelected?.id == undefined) {
        monedaOpDistinta =
          this.monedaOPSelected != null &&
          this.monedaOPSelected != this.monedaPorDefecto;
      } else {
        monedaOpDistinta =
          this.monedaOPSelected != null &&
          this.monedaOPSelected.id != this.monedaPorDefecto;
      }
      let monedaProvDistinta =
        this.monedaProvSelected != null &&
        this.monedaProvSelected.id != this.monedaPorDefecto;

      return (
        this.monedaPorDefecto != null &&
        (monedaOpDistinta || monedaProvDistinta)
      );
    },
    monedaProvDistintaDefecto() {
      return (
        this.monedaProvSelected != null &&
        this.monedaProvSelected?.id !== this.monedaPorDefecto
      );
    },
    monedaExtranjeraNom() {
      let result = "";

      if (
        this.monedaOPSelected != null &&
        this.monedaOPSelected?.id != this.monedaPorDefecto
      ) {
        result = this.monedaOPSelected.value;
      } else if (
        this.monedaProvSelected != null &&
        this.monedaProvSelected.id != this.monedaPorDefecto
      ) {
        result = this.monedaProvSelected.value;
      }
      return result;
    },
    sumaCancelaComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) => parseFloat((acc + item.cancela).toFixed(2)),
              0
            )
          : 0;
      return suma;
    },
    sumaCancelaCompComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) => parseFloat((acc + item.cancelaComp).toFixed(2)),
              0
            )
          : 0;
      return suma;
    },
    sumaNetoComprobantes() {
      let suma =
        this.comprobantes.length > 0
          ? this.comprobantes.reduce(
              (acc, item) => acc + parseFloat(item.netoProporcional),
              0
            )
          : 0;
      return suma;
    },
    computedHeadersComprobantes() {
      let headers = [];
      if (!this.showSaveOpFields) {
        headers = [
          { text: "Comprobante", value: "nroComp", sortable: false, order: 1 },
          {
            text: "Fecha comp.",
            value: "fechaComprobanteFormatted",
            sortable: false,
            order: 2
          },
          {
            text: "Vencimiento",
            value: "fechaVencimientoFormatted",
            sortable: false,
            order: 3
          },
          {
            text: "Total",
            value: "total",
            sortable: false,
            align: "end",
            order: 4
          },
          {
            text: "Cancelado",
            value: "cancelado",
            sortable: false,
            align: "end",
            order: 5
          },
          {
            text: "Saldo",
            value:
              this.monedaProvSelected != null &&
              this.monedaProvSelected.id != this.monedaPorDefecto
                ? "saldoComp"
                : "saldo",
            sortable: false,
            align: "end",
            order: 6
          },
          {
            text: "Cancela",
            value:
              this.monedaProvSelected != null &&
              this.monedaProvSelected.id != this.monedaPorDefecto
                ? "cancelaComp"
                : "cancela",
            sortable: false,
            align: "end",
            order: 7
          },
          {
            text: "Acc.",
            value: "actions",
            sortable: false,
            align: "end",
            order: 10
          }
        ];

        if (
          this.monedaProvSelected != null &&
          this.monedaPorDefecto != this.monedaProvSelected?.id
        ) {
          headers.splice(0, 0, {
            text: "Cotiz. comp.",
            value: "cotizacionComprobante",
            sortable: false,
            align: "end",
            order: 8
          });
          headers.splice(0, 0, {
            text: "Dif. de cambio",
            value: "diferenciaCambio",
            sortable: false,
            align: "end",
            order: 9
          });
        }
      } else {
        headers = [
          { text: "Proveedor", value: "provNom", sortable: false, order: 1 },
          {
            text: "Total neto",
            value: "totalNetoProporcional",
            align: "end",
            sortable: false,
            order: 2
          },
          {
            text: "Importe moneda OP.",
            value: "importeMonedaOP",
            align: "end",
            sortable: false,
            order: 3
          },
          {
            text: "N° OP",
            value: "nroOp",
            sortable: false,
            align: "end",
            order: 5
          },
          {
            text: "Acc.",
            value: "actions",
            sortable: false,
            align: "end",
            order: 6
          }
        ];
        if (this.monedaProvSelected.id != this.monedaOPSelected.id) {
          headers.splice(0, 0, {
            text: "Importe moneda prov.",
            value: "importeMonedaProv",
            align: "end",
            sortable: false,
            order: 4
          });
        }
        if (this.monedaOPSelected.id != this.monedaPorDefecto) {
          headers.splice(0, 0, {
            text: "Dif. cambio",
            value: "totalDifCambio",
            align: "end",
            sortable: false,
            order: 5
          });
        }
      }
      return headers.sort(function(a, b) {
        return a.order - b.order;
      });
    },
    formattedFacturasProv() {
      return this.comprobantes.map(item => `● ${item.nroComp}`).join("<br>");
    }
  },
  async created() {
    await this.setParametrosGeneralesProveedores();
    await this.setCombos();
    this.setFechas();
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "proveedores/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getCotizacionMoneda: "AdministracionSistema/getCotizacionMoneda",
      getTiposComprobanteCargaOrdenesDePago:
        "proveedores/getTiposComprobanteCargaOrdenesDePago",
      getTipoComprobanteProveedorById:
        "proveedores/getTipoComprobanteProveedorById",
      getTipoCompTieneNumAutomatico:
        "proveedores/getTipoCompTieneNumAutomatico",
      getParametroGralFechaContableOp:
        "proveedores/getParametroGralFechaContableOp",
      getTiposValoresOp: "proveedores/getTiposValoresOp",
      getUltimoNumTipoValor: "proveedores/getUltimoNumTipoValor",
      calcularRetenciones: "proveedores/calcularRetenciones",
      getProveedoresSinConceptoGananciasOIB:
        "proveedores/getProveedoresSinConceptoGananciasOIB",
      grabarOp: "proveedores/grabarOpNc",
      getParametrosGeneralesProveedor:
        "proveedores/getParametrosGeneralesProveedorCargaFactura",
      validarChequeNoUsado: "proveedores/validarChequeNoUsado",
      validarChequeDentroPeriodo: "proveedores/validarChequeDentroPeriodo",
      validarNumeroAnteriorUsado: "proveedores/validarNumeroAnteriorUsado",
      getParametroGralValidarChequera:
        "proveedores/getParametroGralValidarChequera",
      saveNumeroTipoValor: "proveedores/saveNumeroTipoValor",
      setAlert: "user/setAlert"
    }),
    async setCombos() {
      this.empresas = await this.getEntidadesFacturantes();
      this.monedas = await this.getMonedasTaxonomy("habCarga");
      let monedaPorDefecto = this.monedas.find(x => x.bool == true);
      this.monedaOPSelected = monedaPorDefecto;
      if (!this.parametroGeneralMultimoneda)
        this.monedaProvSelected = monedaPorDefecto;
      this.monedaPorDefecto = monedaPorDefecto.id;
      this.monedaPorDefectoNom = monedaPorDefecto.value;
      this.siglaMonedaPorDefecto = this.parametroGeneralMultimoneda
        ? monedaPorDefecto.value2
        : "";
      if (this.empresas.length == 1){
        this.empresaSelected = this.empresas[0];
        this.getTiposComprobantes();
        this.getTiposValores();
      }
    },
    async setParametrosGeneralesProveedores() {
      const parametrosGen = await this.getParametrosGeneralesProveedor();
      this.parametroGeneralMultimoneda = parametrosGen.multimoneda;
    },
    async actualizarCotizacion() {
      if (this.showCotizacion) {
        let monedaId = this.monedaProvSelected?.id || this.monedaOPSelected?.id;
        if (monedaId && monedaId != this.monedaPorDefecto) {
          const cotizacion = await this.getCotizacion(monedaId);
          this.cotizacion = cotizacion;
          this.calcularDiferenciaCambio(this.cotizacion);
        }
      }
    },
    async getCotizacion(monedaId) {
      if (monedaId != null) {
        this.cotizacionLoading = true;
        const payload = {
          monedaId: monedaId,
          fechaComprobante: new Date()
        };
        try {
          const response = await this.getCotizacionMoneda(payload);
          return response;
        } catch {
          return 1;
        } finally {
          this.cotizacionLoading = false;
        }
      }
    },
    async changeStep() {
      if (this.opGenerated) {
        this.$router.push({
          name: "OrdenesDePago"
        });
      }
      this.isChangeStepLoading = true;
      const showSaveOpFieldsValue = !this.showSaveOpFields;
      try {
        if (showSaveOpFieldsValue) {
          this.comprobantesGrouped = [];
          
          const data = {
            entFacId: this.empresaSelected?.id,
            provIds: [...new Set(this.comprobantes.map(x => x.provId))]
          };

          this.atLeastOneProvWithError = false;
          const provsSinConcGanIB = await this.getProveedoresSinConceptoGananciasOIB(
            data
          );
          if (provsSinConcGanIB.length > 0) this.atLeastOneProvWithError = true;

          for (const item of this.comprobantes) {
            const {
              provNom,
              cancela,
              cancelaComp,
              diferenciaCambio,
              total,
              neto,
              provId,
              condGanId,
              concGanId,
              condIIBBId,
              concIIBBId,
              cuit,
              direccion,
              tIvaId,
              tvIdModalidadPago
            } = item;

            // Buscamos si ya existe un registro para este proveedor
            let proveedor = this.comprobantesGrouped.find(
              p => p.provNom.trim() === provNom.trim()
            );

            let provSinConcGanIB = provsSinConcGanIB.find(
              p => p.provId == provId
            );
            // Si no existe, creamos uno nuevo
            if (!proveedor) {
              proveedor = {
                mProvId: null,
                provId: provId,
                cuit: cuit,
                direccion: direccion,
                tIvaId: tIvaId,
                condGanId: condGanId,
                concGanId: concGanId,
                condIIBBId: condIIBBId,
                concIIBBId: concIIBBId,
                provNom: provNom.trim(),
                importeMonedaProv: 0,
                importeMonedaOP: 0,
                sumaCancela: 0,
                sumaCancelaComp: 0,
                totalDifCambio: 0,
                totalNetoProporcional: 0,
                retenciones: [],
                total: 0,
                nroOp: null,
                comprobantes: [],
                confGan: provSinConcGanIB.confGan,
                confIB: provSinConcGanIB.confIB,
                tvIdModalidadPago: tvIdModalidadPago,
                error: null
              };
              this.comprobantesGrouped.push(proveedor);
            }

            proveedor.sumaCancela += cancela;
            proveedor.sumaCancelaComp += cancelaComp;

            if (this.monedaPorDefecto == this.monedaOPSelected.id) {
              proveedor.importeMonedaOP += cancela + diferenciaCambio;
              proveedor.importeMonedaProv += cancelaComp;
            } else {
              proveedor.importeMonedaProv += cancela + diferenciaCambio;
              proveedor.importeMonedaOP += cancelaComp;
            }
            proveedor.totalDifCambio += diferenciaCambio;
            proveedor.total += total;
            proveedor.comprobantes = this.comprobantes.filter(
              x => x.provId == provId
            );

            if (total > 0 && cancela > 0) {
              let factor = parseFloat(
                (this.showCotizacion ? cancelaComp : cancela) / total
              );
              proveedor.totalNetoProporcional += neto * factor;
            } else {
              proveedor.totalNetoProporcional += 0;
            }
          }

          for (const item of this.comprobantesGrouped) {
            const data = {
              mProvId: 0,
              entFacId: this.empresaSelected.id,
              provId: item.provId,
              fecha: this.fecha,
              neto: parseFloat(item.totalNetoProporcional),
              total: parseFloat(item.total),
              condGanId: item.condGanId,
              concGanId: item.concGanId,
              condIIBBId: item.condIIBBId,
              concIIBBId: item.concIIBBId,
              listaCompCancelado: item.comprobantes
            };

            item.retenciones = await this.setRetenciones(data);
          }
        }
      } catch {
      } finally {
        const firstTvIdModalidadPago = this.comprobantesGrouped[0]
          .tvIdModalidadPago;

        if (
          this.comprobantesGrouped.every(
            obj => obj.tvIdModalidadPago === firstTvIdModalidadPago
          )
        ) {
          this.tipoValorSelected = this.tiposValores.find(
            x => x.id == firstTvIdModalidadPago
          );
        }

        this.showSaveOpFields = !this.showSaveOpFields;
        this.isChangeStepLoading = false;
      }
    },
    async setRetenciones(data) {
      try {
        return await this.calcularRetenciones(data);
      } catch {}
    },
    aplicarPorcentaje() {
      if (
        !this.porcentajeCancelar ||
        this.porcentajeCancelar <= 0 ||
        this.porcentajeCancelar > 100
      ) {
        this.setAlert({
          text: "Por favor ingresa un porcentaje válido (1-100)",
          color: "error"
        });
        return;
      }

      this.comprobantes.forEach(comp => {
        comp.cancelaComp = comp.saldoComp * (this.porcentajeCancelar / 100);
        comp.cancela = comp.saldo * (this.porcentajeCancelar / 100);
      });

      this.calcularTotales();
    },
    calcularTotales() {
      if (!this.monedaProvDistintaDefecto) {
        //----------total moneda por defecto-----------------
        this.totalEnMonedaPorDefecto = parseFloat(this.sumaCancelaComprobantes);
        //----------total moneda extranjera------------------
        if (this.showCotizacion) {
          let resultMonedaExtranjera = parseFloat(
            this.sumaCancelaCompComprobantes
          );
          if (
            isNaN(this.sumaCancelaCompComprobantes) ||
            this.sumaCancelaCompComprobantes === 0
          ) {
            resultMonedaExtranjera =
              this.sumaCancelaComprobantes / this.cotizacion;
          }
          this.totalEnMonedaExtranjera = resultMonedaExtranjera;
        } else this.totalEnMonedaExtranjera = 0;
      } else if (this.monedaProvDistintaDefecto) {
        //----------total moneda extranjera-----------------
        this.totalEnMonedaExtranjera = parseFloat(
          this.sumaCancelaCompComprobantes
        );
        //----------total moneda por defecto-----------------
        this.totalEnMonedaPorDefecto = parseFloat(
          this.sumaCancelaCompComprobantes * this.cotizacion
        );
        this.comprobantes.forEach(x => {
          if (x.cotizacionComprobante < this.cotizacion)
            x.cancela =
              x.cancelaComp * x.cotizacionComprobante ?? this.cotizacion;
          else x.cancela = x.cancelaComp * this.cotizacion;
        });
      }
    },
    calcularTotalEnPeso() {
      this.totalEnMonedaPorDefecto =
        parseFloat(this.totalEnMonedaExtranjera) * parseFloat(this.cotizacion);
    },
    calcularTotalEnDolar() {
      this.totalEnMonedaExtranjera =
        parseFloat(this.totalEnMonedaPorDefecto) / parseFloat(this.cotizacion);
    },
    calcularDiferenciaCambio(cotizacionParam) {
      if (!cotizacionParam || !Array.isArray(this.comprobantes)) {
        // Si no se ha ingresado una cotización válida o el array de comprobantes no existe, no hacemos nada
        return;
      }

      this.comprobantes = this.comprobantes.map(comprobante => {
        // Verifica si los valores existen y son válidos, en caso de no existir, se usan valores por defecto
        let cancelaComp = comprobante.cancelaComp || 0;
        let cotizacionComprobante = comprobante.cotizacionComprobante || 1; // Evitar división por 0

        // Calcula la diferencia de cambio
        comprobante.diferenciaCambio =
          cancelaComp * (cotizacionParam - cotizacionComprobante);

        // Calcula el monto a cancelar en pesos argentinos según la nueva cotización
        comprobante.cancela =
          cancelaComp *
          (comprobante.diferenciaCambio > 0
            ? comprobante.cotizacionComprobante
            : this.cotizacion ?? 1);

        // Retorna un nuevo objeto con el valor actualizado
        return { ...comprobante };
      });
    },
    async setFechas() {
      let today = new Date();
      let año = today.getFullYear();
      let mes = (today.getMonth() + 1).toString().padStart(2, "0");
      let dia = today
        .getDate()
        .toString()
        .padStart(2, "0");

      let date = `${año}-${mes}-${dia}`;
      let dateFormated = this.helpers.formatDate(date);

      this.fecha = date;
      this.fechaSelected = dateFormated;
      const parametroFechaContableOp = await this.getParametroGralFechaContableOp();
      //si hay fecha en el param gral la seteamos
      if (parametroFechaContableOp !== "") {
        let dia = parametroFechaContableOp.toString().substring(0, 2);
        let mes = parametroFechaContableOp.toString().substring(3, 5);
        let año = parametroFechaContableOp.toString().substring(6, 10);

        this.fechaContable = `${año}-${mes}-${dia}`;
        this.fechaContableSelected = parametroFechaContableOp;
        this.disableFecContable = true;
      } else {
        this.fechaContable = date;
        this.fechaContableSelected = dateFormated;
      }
    },
    async changeTipoValor() {
      this.numero = null;
      this.fechaValorSelected = null;
      if (this.tipoValorSelected != undefined) {
        // se chequea si pide fecha
        if (this.tipoValorSelected.fechaBool) {
          this.disableFechaValor = true;
          // se formatea la fecha con un dia mas y se asignan valores
          const [dia, mes, año] = this.fechaSelected.split("/").map(Number);
          let fecha = new Date(año, mes - 1, dia);
          fecha.setDate(fecha.getDate() + 1);
          const diaSiguiente = ("0" + fecha.getDate()).slice(-2);
          const mesSiguiente = ("0" + (fecha.getMonth() + 1)).slice(-2);
          const añoSiguiente = fecha.getFullYear();
          this.fechaSiguiente = `${diaSiguiente}/${mesSiguiente}/${añoSiguiente}`;
          this.fechaValorSelected = this.fechaSiguiente;
          this.fechaValor = helpers.parseDateToIso(this.fechaSiguiente);
        } else this.disableFechaValor = false;
        // se chequea si pide numero
        if (this.tipoValorSelected.numeroBool) this.disableNumero = true;
        else this.disableNumero = false;
        // se chequea si trae banco
        if (this.tipoValorSelected.bancoId > 0) {
          this.bancoNombre = this.tipoValorSelected.bancoNombre;
        } else {
          this.bancoNombre = null;
        }
        //se chequea si trae cuenta de banco
        if (this.tipoValorSelected.bctaId > 0) {
          this.cuentaNombre = this.tipoValorSelected.bctaNombre;
        } else {
          this.cuentaNombre = null;
        }
        const response = await this.getUltimoNumTipoValor(
          this.tipoValorSelected.tvId
        );
        if (response > 0) this.numero = response + 1;
      } else {
        this.disableFechaValor = false;
        this.disableNumero = false;
      }
    },
    async getTiposComprobantes() {
      if (this.empresaSelected != null) {
        this.tiposComprobantesLoading = true;
        this.tiposComprobantes = await this.getTiposComprobanteCargaOrdenesDePago(
          this.empresaSelected.id
        );
        this.tiposComprobantesLoading = false;
      }
    },
    async getTiposValores() {
      if (this.monedaOPSelected != null && this.empresaSelected != null) {
        this.tiposValoresLoading = true;
        const data = {
          entFacId: this.empresaSelected.id,
          monedaId: this.monedaOPSelected.id
        };
        try {
          const response = await this.getTiposValoresOp(data);
          this.tiposValores = response.filter(x => x.esTransferencia == false);
        } catch (error) {}
        this.tiposValoresLoading = false;
      }
    },
    async setDatosTipoComprobante() {
      if (this.tipoComprobanteSelected != null) {
        const response = await this.getTipoComprobanteProveedorById(
          this.tipoComprobanteSelected.id
        );
        let datosTipoComprobante = response;
        this.tCompSigno = datosTipoComprobante.tCompSigno;
        if (datosTipoComprobante.numAutoLetra.trim().length > 0)
          this.letraComprobante = datosTipoComprobante.numAutoLetra.toString();

        if (datosTipoComprobante.numAutoSuc)
          this.sucursalComprobante = datosTipoComprobante.numAutoSuc.toString();

        await this.getTipoCompNumAutomatico(
          this.tipoComprobanteSelected.id,
          datosTipoComprobante.numAutoLetra,
          datosTipoComprobante.numAutoSuc
        );
        if (
          datosTipoComprobante.numAuto == true &&
          this.tieneNumeradorAutomatico == false
        ) {
          this.setAlert({
            type: "warning",
            message: `No está configurado el numerador autómatico. \n Tipo de comprobante: '${datosTipoComprobante.tCompNombre}' \nLetra: '${datosTipoComprobante.numAutoLetra}' \nSucursal: '${datosTipoComprobante.numAutoSuc}'`
          });
        }
      } else {
        this.letraComprobante = "";
        this.sucursalComprobante = "";
        this.numeroComprobante = "";
        this.tieneNumeradorAutomatico = false;
      }
    },
    async getTipoCompNumAutomatico(tCompId, letra, sucursal) {
      if (this.movProvId == null && letra && tCompId && sucursal) {
        this.numeroComprobante = "0";

        if (tCompId != null && letra != null && sucursal != null) {
          this.tieneNumeradorAutomatico = await this.getTipoCompTieneNumAutomatico(
            {
              tCompId: tCompId?.id ?? tCompId,
              numAutoSuc: sucursal,
              numAutoLetra: letra
            }
          );
        } else this.tieneNumeradorAutomatico = false;
      }
    },
    async generarOrdenesPago() {
      this.loadingSaveBtn = true;
      let promises = [];
      let numeroActual = this.numero;

      for (const item of this.comprobantesGrouped) {
        // calculamos la suma de los campos imp e importeComp en item.retenciones
        let sumaRetencionesImp = null;
        let sumaRetencionesImporteComp = null;
        let impRestado = null;
        let importeCompRestado = null;

        if (this.monedaPorDefecto != this.monedaOPSelected.id) {
          sumaRetencionesImporteComp = item.retenciones.reduce(
            (acc, ret) => acc + ret.importeComp,
            0
          );
          importeCompRestado =
            item.sumaCancelaComp - sumaRetencionesImporteComp;
        }
        sumaRetencionesImp = item.retenciones.reduce(
          (acc, ret) => acc + ret.imp,
          0
        );

        // resto para que me de los importes finales
        impRestado = item.sumaCancela - sumaRetencionesImp;

        //se chequea si es propio o se valida numero de cheque
        if (
          this.tipoValorSelected.validarNumeracionMovimientoBancario ||
          this.tipoValorSelected.esPropio
        ) {
          const dataTipoValor = {
            BCtaId: this.tipoValorSelected.bctaId,
            TvId: this.tipoValorSelected.tvId,
            Numero: numeroActual,
            MProvId: 0
          };
          const chequeUsado = await this.validarChequeNoUsado(dataTipoValor);
          // si se uso el cheque, salta error
          if (chequeUsado) {
            item.error = `El número de cheque ${dataTipoValor.Numero} ya fue utilizado.`;
            this.setAlert({
              type: "error",
              message: item.error
            });
            this.loadingSaveBtn = false;
            continue;
          } else {
            //si no se uso el cheque, se chequea si esta dentro del período, segun parametro gral
            const paramValidarcheq = await this.getParametroGralValidarChequera();
            if (paramValidarcheq) {
              const chequeCorrecto = await this.validarChequeDentroPeriodo(
                dataTipoValor
              );
              if (!chequeCorrecto) {
                item.error = `El número de cheque ${dataTipoValor.Numero} no entra dentro del rango.`;
                this.setAlert({
                  type: "error",
                  message: item.error
                });
                this.loadingSaveBtn = false;
                continue;
              } else {
                const numeroAnteriorUsado = await this.validarNumeroAnteriorUsado(
                  dataTipoValor
                );
                if (!numeroAnteriorUsado && !this.confirmSaveValor) {
                  this.loadingSaveBtn = false;
                  this.openConfirmDialog = true;
                  return;
                } else {
                  const dataToSaveNum = {
                    tvId: this.tipoValorSelected.tvId,
                    numero: numeroActual
                  };
                  const response = await this.saveNumeroTipoValor(
                    dataToSaveNum
                  );
                  if (response) {
                    const itemToPush = {
                      ...this.tipoValorSelected,
                      imp: impRestado,
                      importeComp: importeCompRestado,
                      fecha: this.fechaValor,
                      numero: numeroActual
                    };
                    item.retenciones.push(itemToPush);
                  }
                }
              }
            } else {
              const numeroAnteriorUsado = await this.validarNumeroAnteriorUsado(
                dataTipoValor
              );
              if (!numeroAnteriorUsado && !this.confirmSaveValor) {
                this.loadingSaveBtn = false;
                this.openConfirmDialog = true;
                return;
              } else {
                const dataToSaveNum = {
                  tvId: this.tipoValorSelected.tvId,
                  numero: numeroActual
                };
                const response = await this.saveNumeroTipoValor(dataToSaveNum);
                if (response) {
                  const itemToPush = {
                    ...this.tipoValorSelected,
                    imp: impRestado,
                    importeComp: importeCompRestado,
                    fecha: this.fechaValor,
                    numero: numeroActual
                  };
                  item.retenciones.push(itemToPush);
                }
              }
            }
          }
        } else {
          const itemToPush = {
            ...this.tipoValorSelected,
            imp: impRestado,
            importeComp: importeCompRestado,
            fecha: this.fechaValor,
            numero: numeroActual
          };
          item.retenciones.push(itemToPush);
        }

        const dataOp = {
          sesion: 0,
          mProvId: null,
          fecha: this.fecha,
          fechaVenc: null,
          fecContable: this.fechaContable,
          provId: item.provId,
          nombre: item.provNom,
          cuit: item.cuit,
          direccion: item.direccion,
          tCompId: this.tipoComprobanteSelected.id,
          letra: this.letraComprobante,
          suc: this.sucursalComprobante.toString(),
          numero: parseInt(this.numeroComprobante),
          tIvaId: item.tIvaId,
          neto: item.totalNetoProporcional,
          iva: 0,
          percepcionIva: 0,
          percepcionIb: 0,
          impuestosInternos: 0,
          otrosConceptos: 0,
          total: item.sumaCancela,
          monedaId: this.monedaPorDefecto,
          entFacId: this.empresaSelected.id,
          obs: this.conceptoPago,
          signo: this.tCompSigno,
          condGanId: item.condGanId,
          concGanId: item.concGanId,
          toId: null,
          concIbId: item.concIIBBId,
          condIbId: item.condIIBBId,
          periodo: null,
          provIdCdo: null,
          caeCaiProv: null,
          caeCaiProvFecVen: null,
          tEmision: null,
          totalComp:
            this.monedaOPSelected.id !== this.monedaPorDefecto ||
            this.monedaProvDistintaDefecto
              ? item.sumaCancelaComp
              : 0,
          netoComp:
            this.monedaOPSelected.id !== this.monedaPorDefecto ||
            this.monedaProvDistintaDefecto
              ? item.totalNetoProporcional / this.cotizacion
              : null,
          ivaComp: null,
          percepcionIvaComp: null,
          percepcionIbComp: null,
          impuestosInternosComp: null,
          otrosConceptosComp: null,
          tipoComprobanteDif: "Op",
          monedaIdComp:
            this.monedaOPSelected.id == this.monedaPorDefecto &&
            !this.monedaProvDistintaDefecto
              ? null
              : this.monedaOPSelected.id,
          cotizacion: this.cotizacion,
          listaValores: item.retenciones,
          listaComprobante: item.comprobantes,
          listaPercepcionIB: [],
          listaAlicuota: [],
          listaImputContable: [],
          listaCentrosCostos: []
        };
        promises.push(this.grabarOp(dataOp));
        numeroActual++;
      }
      try {
        const result = await Promise.all(promises);
        let provsOpGenerated = [];
        for (
          let i = 0;
          i < result.length && i < this.comprobantesGrouped.length;
          i++
        ) {
          if (result[i].idError == 0) {
            this.comprobantesGrouped[i].nroOp = result[i].mensaje;
            this.comprobantesGrouped[i].mProvId = result[i].mProvId;
            provsOpGenerated.push(this.comprobantesGrouped[i].provNom);
          } else {
            this.comprobantesGrouped[i].error = result[i].mensaje;
          }
        }
        if (provsOpGenerated.length > 0) {
          this.setAlert({
            type: "success",
            message: `Fueron generadas con éxito las órdenes de pago de los siguientes proveedores: ${provsOpGenerated.join(
              ", "
            )}. `
          });
        }
        this.opGenerated = true;

        let exportResult = [];
        this.comprobantesGrouped?.forEach(x =>
          exportResult.push({
            ["Proveedor"]: x.provNom,
            ["Total neto"]: x.totalNetoProporcional,
            ["Importe moneda OP."]: x.importeMonedaOP,
            ["N° OP"]: x.nroOp,
            ["Error"]: x.error
          })
        );
        let formato = {
          filas: exportResult,
          hoja: "Resultado"
        };
        helpersExcel.excelExport(formato, "Pago masivo generado");
      } catch {
        this.opGenerated = false;
      }
      this.loadingSaveBtn = false;
    },
    deleteItem(item) {
      if (!this.showSaveOpFields) {
        this.comprobantes = this.comprobantes.filter(x => x !== item);
      } else {
        this.comprobantesGrouped = this.comprobantesGrouped.filter(
          x => x !== item
        );
        this.comprobantes = this.comprobantes.filter(
          x => x.provNom.trim() != item.provNom
        );
      }
    },
    goToVerComprobante(item) {
      this.openSeeComp = true;
      this.paramsToSeeComp = {
        movProvId: item.mProvId,
        monedaToDetalle: this.monedaProvSelected,
        fechaCotizaToDetalle: this.fecha
      };
    },
    quitarComprobantes() {
      this.comprobantes = [];
      if (this.showSaveOpFields) {
        this.comprobantesGrouped = [];
        this.showSaveOpFields = false;
      }
    },
    toggleModalComprobantesCancelar() {
      this.buscarComprobantesCancelar = !this.buscarComprobantesCancelar;
    },
    setComprobantesCancelar(itemsComprobantesACancelar) {
      if (itemsComprobantesACancelar) {
        itemsComprobantesACancelar.forEach(x => {
          //hacemos que pushee solo los nuevos comprobantes ignorando los repetidos
          if (!this.comprobantes.some(y => y.mProvId === x.mProvId))
            this.comprobantes.push(x);
        });
      }
      this.calcularTotales();
    },
    getComprobantesList(provId) {
      // Filtra los comprobantes por provId y los formatea
      return this.comprobantes
        .filter(x => x.provId === provId)
        .map(x => `● ${x.nroComp}`)
        .join("<br />");
    },
    exportComprobantes() {
      let result = [];
      if (this.monedaProvSelected.id !== this.monedaPorDefecto) {
        this.comprobantes?.forEach(x =>
          result.push({
            ["Proveedor"]: x.provNom,
            ["Comprobante"]: x.nroComp,
            ["Fecha"]: x.fechaComprobanteFormatted,
            ["Vencimiento"]: x.fechaVencimientoFormatted,
            ["Total"]: x.totalFormatted,
            ["Cancelado"]: x.canceladoFormatted,
            ["Saldo"]: x.saldoFormatted,
            ["Cancela"]: helpers.floatToMoneyString(x.cancela, "", true),
            [`Cancela ${this.monedaExtranjeraNom}`]: helpers.floatToMoneyString(
              x.cancelaComp,
              "",
              false
            ),
            ["Cotización"]: x.cotizacionComprobanteFormatted,
            ["Diferencia de cambio"]: helpers.floatToMoneyString(
              x.diferenciaCambio,
              "",
              false
            ),
            ["Formula diferencia de cambio"]: `(${x.cancelaComp} * ${this.cotizacion}) - (${x.cancelaComp} * ${x.cotizacionComprobante})`
          })
        );
      } else {
        this.comprobantes?.forEach(x =>
          result.push({
            ["Proveedor"]: x.provNom,
            ["Comprobante"]: x.nroComp,
            ["Fecha"]: x.fechaComprobanteFormatted,
            ["Vencimiento"]: x.fechaVencimientoFormatted,
            ["Total"]: x.totalFormatted,
            ["Cancelado"]: x.canceladoFormatted,
            ["Saldo"]: x.saldoFormatted,
            ["Cancela"]: helpers.floatToMoneyString(x.cancela, "", true),
            ["Cotización"]: x.cotizacionComprobanteFormatted,
            ["Diferencia de cambio"]: helpers.floatToMoneyString(
              x.diferenciaCambio,
              "",
              false
            ),
            ["Fórmula diferencia de cambio"]: `(${x.cancela} * ${this.cotizacion}) - (${x.cancela} * ${x.cotizacionComprobante})`
          })
        );
      }
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Comprobantes a cancelar");
    },
    goBack() {
      this.$router.push({
        name: this.routeToGo
      });
    }
  }
};
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
.mt-2 {
  margin-top: 8px;
}
.custom-data-table >>> .v-data-table-header th,
.custom-data-table >>> .v-data-table__divider,
.custom-data-table >>> .v-data-table__wrapper td,
.custom-data-table >>> .v-data-table__mobile-table-row {
  padding: 0px 8px;
  margin: 0;
}
.no-upper-case {
  text-transform: none;
}
</style>
